import React from "react";
import "../css/header.css";
import background from "../images/prueba1.webp";

function Header() {
  return (
    <div
      className="contenedor-header"
      style={{ backgroundImage: `url(${background})` }}
    >
      <header>
        <h1>Sofía</h1>
        <h1>&</h1>
        <h1>Nacho</h1>
      </header>
      <h2> ¡ Nos casamos ! </h2>
    </div>
  );
}

export default Header;
