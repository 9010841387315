import "./App.css";
import Header from "./components/Header";
import React from "react";

const Information = React.lazy(() => import("./components/Information"));
const Nosotros = React.lazy(() => import("./components/Nosotros"));
const Location = React.lazy(() => import("./components/Location"));
const Gift = React.lazy(() => import("./components/Gift"));
const Timetable = React.lazy(() => import("./components/Timetable"));
const Tips = React.lazy(() => import("./components/Tips"));

function App() {
  return (
    <div className="App">
      <Header />
      <React.Suspense fallback={<div>Loading...</div>}>
        <Information />
        <Nosotros />
        <Location />
        <Tips />
        <Timetable />
        <Gift />
      </React.Suspense>
    </div>
  );
}

export default App;
